import React from 'react';
import Script from 'next/script';

const FirebaseConfigScript = () => {
  return (
    <Script id="firebase-config-comp" strategy="afterInteractive">
      {`
        /* eslint-disable */
        (function (firebaseApp) {
          if (!firebaseApp) {
            console.log('Will not call firebase locally');
            return;
          }
          
          var firebaseConfig = {
            apiKey: "${FIREBASE_KEY}",
            authDomain: "${FIREBASE_AUTH}",
            databaseURL: "${FIREBASE_DATABASE_URL}",
            projectId: "${FIREBASE_PROJECT_ID}",
            storageBucket: "${FIREBASE_STORAGE_BUCKET}",
            messagingSenderId: "${FIREBASE_MESSAGING_ID}",
            appId: "${FIREBASE_APP}"
          };
          
          // First Delay Input (0.1.3) minified code
          // https://github.com/GoogleChromeLabs/first-input-delay#first-input-delay
          !function (n, e) { var t, o, i, c = [], f = { passive: !0, capture: !0 }, r = new Date, a = "pointerup", u = "pointercancel"; function p(n, c) { t || (t = c, o = n, i = new Date, w(e), s()) } function s() { o >= 0 && o < i - r && (c.forEach(function (n) { n(o, t) }), c = []) } function l(t) { if (t.cancelable) { var o = (t.timeStamp > 1e12 ? new Date : performance.now()) - t.timeStamp; "pointerdown" == t.type ? function (t, o) { function i() { p(t, o), r() } function c() { r() } function r() { e(a, i, f), e(u, c, f) } n(a, i, f), n(u, c, f) }(o, t) : p(o, t) } } function w(n) { ["click", "mousedown", "keydown", "touchstart", "pointerdown"].forEach(function (e) { n(e, l, f) }) } w(n), self.perfMetrics = self.perfMetrics || {}, self.perfMetrics.onFirstInputDelay = function (n) { c.push(n), s() } }(addEventListener, removeEventListener);
          
          // Initialize Firebase through performance only method
          // for docs: https://firebase.google.com/docs/perf-mon/get-started-web#cdn_standalone-sdk
          (function (sa, fbc) {
            function load(f, c) {
              var a = document.createElement('script');
              a.async = 1; a.src = f; var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(a, s);
            } load(sa); window.onload = function () { firebase.initializeApp(fbc).performance(); };
          })('https://www.gstatic.com/firebasejs/6.0.2/firebase-performance-standalone.js', firebaseConfig);
        })("${FIREBASE_APP}")
      `}
    </Script>
  );
};

export default FirebaseConfigScript;
