import React from 'react';
import Script from 'next/script';

const AmplitudeConfigScript = () => {
  return (
    <Script id="amplitude-config-comp" strategy="afterInteractive">
      {`
        function readCookie(name) {
          var nameEQ = name + '=';
          var ca = document.cookie.split(';');
          var c;
        
          for (var i = 0; i < ca.length; i += 1) {
            c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
          }
          return null;
        }
        
        (function (e, t) {
          var n = e.amplitude || { _q: [], _iq: {} };
          var r = t.createElement('script');
          r.type = 'text/javascript';
          r.integrity = 'sha384-cukXwabQy+j/QA1+RqiXSzxhgQg5Rrn3zVszlwH3pWj/bXJxlA8Ge7NhcD6vP2Ik';
          r.crossOrigin = 'anonymous';
          r.async = true;
          r.src = 'https://cdn.amplitude.com/libs/amplitude-7.1.0-min.gz.js';
          r.onload = function () {
            if (!e.amplitude.runQueuedFunctions) {
              console.log('[Amplitude] Error: could not load SDK');
            }
          };
          var i = t.getElementsByTagName('script')[0];
          i.parentNode.insertBefore(r, i);
          function s(e, t) {
            e.prototype[t] = function () {
              this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              return this;
            };
          }
          var o = function () {
            this._q = [];
            return this;
          };
          var a = ['add', 'append', 'clearAll', 'prepend', 'set', 'setOnce', 'unset'];
          for (var c = 0; c < a.length; c++) {
            s(o, a[c]);
          }
          n.Identify = o;
          var u = function () {
            this._q = [];
            return this;
          };
          var l = ['setProductId', 'setQuantity', 'setPrice', 'setRevenueType', 'setEventProperties'];
          for (var p = 0; p < l.length; p++) {
            s(u, l[p]);
          }
          n.Revenue = u;
          var d = [
            'init',
            'logEvent',
            'logRevenue',
            'setUserId',
            'setUserProperties',
            'setOptOut',
            'setVersionName',
            'setDomain',
            'setDeviceId',
            'enableTracking',
            'setGlobalUserProperties',
            'identify',
            'clearUserProperties',
            'setGroup',
            'logRevenueV2',
            'regenerateDeviceId',
            'groupIdentify',
            'onInit',
            'logEventWithTimestamp',
            'logEventWithGroups',
            'setSessionId',
            'resetSessionId'
          ];
          function v(e) {
            function t(t) {
              e[t] = function () {
                e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              };
            }
            for (var n = 0; n < d.length; n++) {
              t(d[n]);
            }
          }
          v(n);
          n.getInstance = function (e) {
            e = (!e || e.length === 0 ? '$default_instance' : e).toLowerCase();
            if (!n._iq.hasOwnProperty(e)) {
              n._iq[e] = { _q: [] };
              v(n._iq[e]);
            }
            return n._iq[e];
          };
          e.amplitude = n;
        })(window, document);
        
        (function () {
          var user;
          var userCookie = readCookie('user_attributes');
          var userId = null;
        
          if (userCookie) {
            user = JSON.parse(decodeURIComponent(userCookie));
            if (user && user.id) {
              userId = user.id;
            }
          }
        
          amplitude.getInstance().init("${AMPLITUDE_KEY}", userId, {
            includeUtm: true,
            includeReferrer: true,
            includeGclid: true,
            saveParamsReferrerOncePerSession: false
          });
        })();
      `}
    </Script>
  );
};

export default AmplitudeConfigScript;
