import React from 'react';
import Script from 'next/script';

const GoogleAnalyticsScript = () => {
  return (
    <>
      <Script id="google-analytics-script" strategy="afterInteractive">
        {`
          window.ga =
          window.ga ||
          function () {
            (ga.q = ga.q || []).push(arguments);
          };
          ga.l = +new Date();
          
          ga('create', '${GA_ID}', 'auto');
          
          function readCookie(name) {
            var nameEQ = name + '=';
            var ca = document.cookie.split(';');
            var c;
          
            for (var i = 0; i < ca.length; i += 1) {
              c = ca[i];
              while (c.charAt(0) === ' ') c = c.substring(1, c.length);
              if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
          }
          
          function setAnalyticsUserId() {
            var userCookie = readCookie('user_attributes');
            var masquerading = readCookie('masquerading_as');
            if (userCookie) {
              var user = JSON.parse(decodeURIComponent(userCookie));
              if (user && user.id) {
                ga('set', 'userId', user.id.toString());
                ga('set', 'dimension1', user.id.toString());
              }
            }
          }
          
          setAnalyticsUserId();
          ga('send', 'pageview');
        `}
      </Script>
      <Script src="https://www.google-analytics.com/analytics.js" strategy="afterInteractive" />
    </>
  );
};

export default GoogleAnalyticsScript;
